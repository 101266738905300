<template>
  <div  >
    <transition name="van-slide-left">
    <div v-show="visible">


      <div class="head">
        <div style="padding: 30px;float: left">
          <van-button round type="info" :color="buttonColor1" style="font-size: 20px" @click="queryClick(0)">
            <strong>收入</strong></van-button>
          <van-button round type="info" :color="buttonColor2" style="font-size: 20px" @click="queryClick(1)">
            <strong>支出</strong></van-button>
          <div
              class="data-class"
              @click="showPicker = true">
            <p>{{ keepTime === '' ? '请选择日期' : keepTime }}</p>
          </div>
          <van-popup v-model="showPicker" round position="bottom">
            <van-datetime-picker
                type="year-month"
                title="选择年月"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="selectDate"
                @cancel="showPicker = false"
            />
          </van-popup>
        </div>
        <div style="float: right;padding: 20px 40px 0 0">
          <p>
            <van-button round type="warning" style="font-size: 20px" @click="reset"><strong>重置</strong></van-button>
          </p>
          <p>
            <van-button round type="info" style="font-size: 20px" to="/addCost"><strong>记账</strong></van-button>
          </p>
        </div>
      </div>


      <div>
        <van-pull-refresh v-model="isLoading" @refresh="getKeepList">
          <van-index-bar :index-list="indexList" v-show="!isEmpty">
            <div v-for="(item,index) in keepHome" :key="index">
              <van-index-anchor :index="cutOut(item.keepMTime)"><span>{{
                  item.keepMTime
                }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>总收入：{{ item.countPaymentTypeZero }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>总支出：{{ item.countPaymentTypeOne }}</span>
              </van-index-anchor>
              <van-cell-group v-for="(keepDay,index1) in item.keepDayList" :key="index1">
                <van-cell :title="keepDay.keepMTime"
                          :value="'出：'+keepDay.paymentTypeOne+'；入:'+keepDay.paymentTypeZero"/>
                <van-cell v-for="(keep,index2) in keepDay.list" :key="index2" is-link :to="{path:'/cost',query:{keepAccountsId:keep.id}}">
                  <template #icon>
                    <i style="font-size: 25px;color: #e33d33" class="iconfont icon-jizhangben"></i>
                  </template>
                  <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                  <template #default>
                    <strong v-if="keep.paymentType === 0" style="font-size: 18px;margin-right: 30px;color: #333333">￥+{{
                        keep.money
                      }}</strong>
                    <strong v-if="keep.paymentType === 1"
                            style="font-size: 18px;color: #e33d33;margin-right: 30px">￥-{{ keep.money }}</strong>
                  </template>
                  <template #title>
                    <strong style="color: #333333;font-size: 18px">
                      &nbsp;&nbsp;{{ keep.feeTypeName }}
                    </strong>
                    &nbsp;&nbsp;<van-tag plain type="danger" v-if="keep.status === 0"> 未提交</van-tag>
                    <van-tag plain type="success" v-if="keep.status === 1"> 已提交</van-tag>
                    <van-tag plain type="primary" v-if="keep.status === 2"> 已纳税</van-tag>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </van-index-bar>
          <van-empty description="没有数据" v-show="isEmpty"/>

        </van-pull-refresh>
      </div>
    </div>
  </transition>

  <BottomBar/>

  </div>

</template>

<script>
import BottomBar from "@/components/BottomBar";
import {getKeepList} from "@/api/home/home";

export default {
  name: "home",
  data() {
    return {
      visible:false,
      isEmpty: false,
      loading: true,
      isLoading: false,
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2025, 11, 1),
      currentDate: new Date(),
      keepHome: [],
      indexList: [],
      showPicker: false,
      direction: 'down',
      keepTime: '',
      paymentType: '',
      dateSelectShow: false,
      buttonColor1: '#e33d33',
      buttonColor2: '#e33d33',
    }
  },
  components: {
    BottomBar,
  },
  methods: {
    selectDate(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      mm = mm.toString().padStart(2, '0');
      this.keepTime = yy + "年" + mm + "月";
      this.showPicker = false;
      this.getKeepList();
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    queryClick(val) {
      this.paymentType = val
      this.getKeepList();
      if (val === 0) {
        this.buttonColor1 = '#cd372e'
        this.buttonColor2 = '#e33d33'
      } else {
        this.buttonColor2 = '#cd372e'
        this.buttonColor1 = '#e33d33'
      }
    },
    reset() {
      this.buttonColor1 = '#e33d33'
      this.buttonColor2 = '#e33d33'
      this.paymentType = '';
      this.keepTime = '';
      this.getKeepList();
    },
    formatDate(date) {
      return `${date.getFullYear()} / ${date.getMonth() + 1} / ${date.getDate()}`;
    },
    async getKeepList() {
      this.isLoading = true;
      let res = await getKeepList({
        paymentType: this.paymentType,
        keepTime: this.keepTime
      });
      this.isLoading = false;
      if (res.length > 0) {
        this.isEmpty = false;
        this.keepHome = res;
        this.indexList = [];
        this.keepHome.forEach(item => {
          this.indexList.push(this.cutOut(item.keepMTime))
        })
      } else {
        this.isEmpty = true;
        // this.$toast("为查询到数据")
      }

    },
    cutOut(str) {
      let index = str.indexOf("年");
      return str.substr(index + 1, str.length);   //04
    }
  },
  mounted() {
    this.visible=true
    var date = new Date();
    var hengGang = " / ";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var curDate = date.getDate();
    this.dateTime = year + hengGang + month + hengGang + curDate;
    this.getKeepList();
    document.title = this.$route.meta.title
  }

}
</script>

<style scoped lang="less">
.head {
  width: 100vw;
  height: 200px;
  border-radius: 0 0 30px 30px;
  background-color: #e33d33;
  box-shadow: 0px 15px 10px -15px;
}

.data-class {
  text-align: center;
  line-height: 40px;
  margin-top: 30px;
  background-color: #cd372e;
  font-size: 18px;
  color: white;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  @field-input-text-color: #fff

}

.van-cell::after {
  border-bottom: 0px solid #ebedf0 !important;
}

.van-field__control {
  color: #FFFFFF !important;
}

.iconfont {
  /*color: #e33d33;*/
}

/*#cd372e*/
</style>
