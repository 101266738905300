import request from "@/api/request";


/**
 * 查询所有记账记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepList(params) {
    return await request.post("/keep/getKeepList",params)
}
